import { DocumentReference, Query, onSnapshot } from '@angular/fire/firestore';
import { DocumentSnapshot, QuerySnapshot } from '@firebase/firestore';
import { Observable } from 'rxjs';

export function getRealtimeDocData<R>(ref: DocumentReference): Observable<R> {
  return new Observable((observer) => {
    return onSnapshot(
      ref,
      (snapshot: DocumentSnapshot) => observer.next(snapshot.data() as R),
      (error: Error) => observer.error(error.message)
    );
  });
}

export function getRealtimeDocsData<R>(ref: Query): Observable<R> {
  return new Observable((observer) => {
    return onSnapshot(
      ref,
      (snapshot: QuerySnapshot) => observer.next(snapshot.docs.map((d) => d.data()) as R),
      (error: any) => observer.error(error.message)
    );
  });
}
